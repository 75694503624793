import React, { useEffect, useState } from "react";
import moment from "moment";
import BarChart from "./BarChart";
import DataTable from "./DataTable";
import IndoorClimate from "./IndoorClimate";
import "./Report.css";
import Card from "../Reusablecomponents/Card";
import { ReportApi } from "../api";
import CropSelector from "../Reusablecomponents/CropSelector";

function useSensorData(cropId) {
    const [sensorData, setSensorData] = useState(null);
    const [fetchError, setFetchError] = useState(false);
    const [noData, setNoData] = useState(false); // New state to track 400/404

    useEffect(() => {
        if (!cropId) return;
        (async () => {
            const newSensorData = await ReportApi.fetchSensorData(cropId);
            if (newSensorData === null) {
                setNoData(true);
            } else {
                setNoData(false);
                setSensorData(newSensorData);
            }
        })().catch((error) => {
            console.error(error);
            setFetchError(true);
        });
    }, [cropId]);

    return { sensorData, setSensorData, fetchError, noData };
}

function Report({ orgIdOrUuid }) {
    const [data, setData] = useState();
    const [yieldPrediction, setYieldPrediction] = useState();
    const [isHarvestChanged, setIsHarvestChanged] = useState(false);
    const [currentHarvestValue, setCurrentHarvestValue] = useState();
    const [futureHarvestValue, setFutureHarvestValue] = useState();
    const [yeildm2, setYeildm2] = useState();
    const [cropId, setCropId] = useState();
    const [previousPredictionValues, setPreviousPredictionValues] = useState();
    const { sensorData, setSensorData, fetchError, noData } = useSensorData(cropId);
    useEffect(() => {
        setIsHarvestChanged(false);
        setData(null);
        setYieldPrediction(null);
    }, [cropId]);

    useEffect(() => {
        if (Boolean(data) || !cropId) {
            return;
        }
        const fetchData = async () => {
            const data = await ReportApi.fetchHistoryEvents(cropId);
            if (!data) return;
            setData(data.splice(-5)); // use at most 5 last entries
        };

        fetchData().catch((error) => {
            console.error("Fetch error:", error);
            setData(null);
        });
    }, [data, cropId]);

    if (fetchError) {
        return <div>Error occurred while fetching fetchHistoryEvents.</div>;
    }

    const handleYieldPrediction = (data) => {
        setYieldPrediction(data);
    };

    const getHarvestValues = (currentHarvest, futureHarvest, isChanged) => {
        setCurrentHarvestValue(currentHarvest);
        setFutureHarvestValue(futureHarvest);
        setIsHarvestChanged(isChanged);
    };

    const entryList = [...(data || []), ...(yieldPrediction || [])];
    const adjustedEntryList = entryList.reduce((acc, entry, index) => {
        if (!entry) return acc;
        let calendar_week = entry.calendar_week;
        if (index > 0 && calendar_week === acc[index - 1].calendar_week) {
            calendar_week = acc[index - 1].calendar_week + 1;
        }
        acc.push({
            ...entry,
            calendar_week,
        });

        return acc;
    }, []);
    const formattedData = adjustedEntryList.map((entry, index) => {
        if (!data) return {};
        const startDate = moment(entry["date_time"]).format("DD.MM.YY");
        const endDate = moment(entry["date_time"]).add(6, 'days').format("DD.MM.YY");
        const date = `${startDate} - ${endDate}`;
        const fruits = entry["prognosis"] || entry["yield_prediction"];
        const calendar_week = entry["calendar_week"];
        const harvestWeightFuture = entry["harvested_weight_future"];
        let harvestEventVolume = entry["harvested_weight"];
        if (index === data.length - 1) {
            harvestEventVolume = !currentHarvestValue
                ? data[data.length - 1]["harvested_weight"]
                : currentHarvestValue;
        } else if (index === data.length) {
            harvestEventVolume = futureHarvestValue;

        }

        const harvestVsPredictedCropPercentage = ((harvestEventVolume / fruits) * 100).toFixed(2);
        const cultivation_area = entry["cultivation_area"] || NaN;
        const yield_prediction = entry["yield_prediction"];
        const prognosis = entry["prognosis"];

        const harvestable_fruitsm2 = yield_prediction
            ? (yield_prediction / yeildm2).toFixed(2)
            : (prognosis ? (prognosis / yeildm2).toFixed(2) : NaN);

        return {
            harvestWeightFuture,
            calendar_week,
            date,
            harvestable_fruitsm2: harvestable_fruitsm2,
            harvestable_fruits: fruits,
            harvestEventVolume,
            harvestVsPredictedCropPercentage,
        };
    });
    return (
        <div
            className="report-container"
            style={{ display: "flex", flexDirection: "column" }}
        >
            <Card className="chart-container" height={'auto'}>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: "-30px",
                    }}
                >
                    <div
                        style={{ color: "#05668D", fontSize: "32px", fontWeight: "800" }}
                    >
                        Harvest Volume Manipulation
                    </div>
                    <div>
                        <IndoorClimate
                            yieldPrediction={yieldPrediction}
                            setYieldPrediction={handleYieldPrediction}
                            setPreviousPredictionValues={setPreviousPredictionValues}
                            sensorData={sensorData}
                            setSensorData={setSensorData}
                            getHarvestValues={getHarvestValues}
                            cropId={cropId}
                            baseHarvestData={data && data[data.length - 1]}
                            futureHarvestData={data && data[data.length]}
                            noData={noData}
                            orgIdOrUuid={orgIdOrUuid}
                        />
                    </div>
                </div>

                <CropSelector setCropId={setCropId} orgIdOrUuid={orgIdOrUuid} setYeildm2={setYeildm2} />
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    {noData ? (
                        <div className="no-data-container">
                            <div className="no-data-title">Data Unavailable</div>
                            <div className="no-data-message">
                                Yield predictions are currently not accessible. This may be due to seasonal factors or other data-related reasons. If you don't recognize the cause, please reach out to us at&nbsp;
                                <a style={{ whiteSpace: 'nowrap' }} href="mailto:support@harvest-ai.com" className="no-data-link">
                                    support@harvest&#8209;ai.com
                                </a>{' '}
                                for assistance.
                            </div>
                        </div>

                    ) : data && sensorData ? (
                        <BarChart
                            props={formattedData}
                            historyLength={data.length}
                            sensorData={sensorData}
                            isHarvestChanged={isHarvestChanged}
                            previousPredictionValues={previousPredictionValues}
                        />
                    ) : (
                        <div>Loading Bar Chart...</div>
                    )}
                </div>
            </Card>

            <Card className="table-container" header="Detail">

                {
                    noData ? (
                        <div>No data available</div>
                    ) : data && sensorData ? (
                        // Remove harvestWeightFuture before the data is shown in the table
                        <DataTable props={formattedData.map(({ harvestWeightFuture, ...rest }) => rest)} historyLength={data.length} />
                    ) : (
                        <div>Loading Data Table...</div>
                    )}
            </Card>
        </div>
    );
}

export default Report;
